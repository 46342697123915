<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <div>
        <div class="product-key-benefits-title">{{ title }}</div>
        <mds-layout-grid class="product-key-benefits">
          <mds-row class="product-key-benefits-row" align-vertical="center">
            <mds-col
              v-for="(item, index) in benefitsList"
              align-vertical="center"
              :key="`key-benefits-${index}`"
              :cols="12"
              :cols-at-m="6"
            >
              <ul>
                <li class="product-key-benefits-row-item">{{ item }}</li>
              </ul>
            </mds-col>
          </mds-row>
          <mds-row>
            <mds-col :cols="12">
              <mds-button 
                class="product-key-benefits-action-btn"
                variation="primary"
                size="large"
                @click="signIn"
              >
                Request a Demo
              </mds-button>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
export default {
  name: 'KeyBenefits',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
  },
  data() {
    return {
      title: 'Key Benefits',
    };
  },
  props: {
    benefitsList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    signIn() {
      this.$emit('redirectToGetStartedForm');
    }
  }
};
</script>

<style lang="scss" scoped>
.product-key-benefits {
  height: auto;
  min-height: 200px;
  margin: 0 auto;
  &-title {
    text-align: center;
    @include mds-level-3-heading($bold: true);
    color: $mds-text-color-secondary;
    padding: $mds-space-2-x 0;
  }
  &-action-btn {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
  }
  &-row {
    margin-top: $mds-space-4-x;
    margin-bottom: $mds-space-4-x;
    &-item {
      @include mds-body-text-l();
      color: $mds-text-color-secondary;
      padding: $mds-space-1-x 0;
    }
  }
}
ul {
  @include mds-unordered-list(large);
}
ul li {
  &::before{
    color: $mds-text-color-secondary;
  }

}

.mcia-outer-conatiner {
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $mds-space-4-x;
    padding-bottom: $mds-space-4-x;
  }
}
</style>
